import React, { useContext, useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
// import * as styles from './EducationFundVideo.module.scss';
import '../../styles/popupVideos.scss';
import { GeneralContext } from '../../context/GeneralContext';

export default function EducationFundVideo({ vidURL }) {
  const modalValue = useContext(GeneralContext);
  const closeBtn = useRef();
  const modalBG = useRef();

  const closeModal = (e) => {
    if (
      e.target === closeBtn.current ||
      e.target === modalBG.current ||
      e.target.parentNode === closeBtn.current
    ) {
      modalValue.setEducationFundVideoModal(false);
    }
  };

  return (
    <div
      className={`${modalValue.educationFundVideoModal ? 'openModal' : null} wrapper`}
      onClick={closeModal}
      ref={modalBG}
      id='popup-video'
    >
      <div className='holder'>
        <button
          className='closeModal mb-auto'
          type='button'
          onClick={closeModal}
          ref={closeBtn}
          aria-label='Closes popup video about TAIK Scholars Program'
        >
          <h3>X</h3>
        </button>

        <div className='w-100 h-100 mx-auto text-center'>
          {!vidURL && (
            <h4>
              Video not found, please refresh page and click the play button.
              <small> Generic Video goes here</small>
            </h4>
          )}
          {vidURL && (
            <ReactPlayer
              width='100%'
              height='100%'
              controls
              playing={modalValue.educationFundVideoModal}
              url={vidURL}
            />
          )}
        </div>
      </div>
    </div>
  );
}

EducationFundVideo.defaultProps = {
  vidURL: 'https://youtu.be/IVxqxpb-PRU',
};

EducationFundVideo.propTypes = {
  vidURL: PropTypes.string,
};
