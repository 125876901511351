import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, Projects } from '@components';
import HomeVideo from '../popups/HomeVideo';
import ScholarsVideo from '../popups/ScholarsVideo';
import EducationFundVideo from '../popups/EducationFundVideo';
import Taik54Video from '../popups/Taik54Video';

const { Banner } = Banners;

const Index = () => (
  <Layout
    bannerChildren={
      <Banner
        title='Projects'
        description="TAIK's projects are centered around media and education. Learn more about all our current projects and donate to help us carry those projects to fruition."
      />
    }
  >
    <SEO
      title='PROJECTS'
      description="TAIK's projects are centered around media and education. Learn more about all our current projects and donate to help us carry those projects to fruition."
    />
    <Projects />
    <HomeVideo />
    <ScholarsVideo vidURL='https://youtu.be/tJRQ9LubAEM' />
    <EducationFundVideo vidURL='https://youtu.be/pLGkkunhU1Y' />
    <Taik54Video vidURL='https://youtu.be/Bn4jKQFywFk' />
  </Layout>
);

export default Index;
